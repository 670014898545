import { useState } from "react";
import s from "./PopularSectionOfBoard.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CabinetAccordionSvgSelector from "../PersonalCabinet/PersonalCabinetAccordion/CabinetAccordionSvgSelector";
import { OneCategoryType } from "../../types/reduxStoreTypes";
export default function CategoryHomeMobile({ categories }: { categories: [] }) {
	const [isOpen, setIsOpen] = useState(true);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const goByLink = (item: any) => {
		if (item?.filter_link) {
			window.open(item.filter_link, "_blank");
		} else {
			navigate(item.filter_link === null ? `c/${item.alias}` : "");
		}
	};

	return (
		<>
			<div className={s.mainCategoryMobileTopBlock}>
				<p className={s.mainCategoryMobileTopTitle}>{t("Категорії")}</p>
				<div
					className={`${s.mainCategoryMobileTopSwitcherBlock} ${isOpen ? s.switcher_active : ""}`}
					onClick={() => setIsOpen(!isOpen)}
				>
					{isOpen ? <p>{t("Згорнути")}</p> : <p>{t("Розгорнути")}</p>}

					<CabinetAccordionSvgSelector id={"expandIcon"} color="#4975B0" />
				</div>
			</div>
			<div
				className={`${s.mainCategoryMobileWrap} ${isOpen ? "" : s.hiddenCategory}`}
			>
				{categories
					?.filter((item: OneCategoryType) => item.plus_18 === 0)
					.map((item: any) => (
						<div key={item.id} className={s.mainCategoryMobileBlock}>
							<div
								onClick={() => goByLink(item)}
								className={s.mainCategoryMobileItem}
							>
								<div className={s.mainCategoryMobileImageBlock}>
									<img
										src={item?.icon || ""}
										alt=""
										className={s.mainCategoryMobileImage}
									/>
								</div>
								<p className={s.mainCategoryMobileText}>{item?.name || ""}</p>
							</div>
						</div>
					))}
			</div>
		</>
	);
}
