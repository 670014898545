import React, { useEffect, useRef, useState } from "react";
import s from "./MainSearchLocation.module.css";
import MainSearchSvgSelector from "../MainSearchSvgSelector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { StateType } from "../../../redux/redux-store";
import {
	getClearHistoryPlaces,
	getHistoryPlaces,
} from "../../../redux/search_Reducer";
import {
	getSettlementList,
	setSearchingLocationInfo,
	setSettlementList,
} from "../../../redux/settlement_Reducer";
import { SearchingLocations } from "../../../types/settlementTypes";

type Props = {
	setOpen: (b: boolean) => void;
	openMenu: boolean;
	setOpenMenu: (b: boolean) => void;
};
const MainSearchLocation: React.FC<Props> = ({
	setOpen,
	openMenu,
	setOpenMenu,
}) => {
	const currentSearchInfo = useSelector(
		(state: StateType) => state.settlementData.searchingLocationInfo
	);
	const dispatch: any = useDispatch();
	const { t } = useTranslation();
	const [value, setValue] = useState(
		currentSearchInfo.district === ""
			? currentSearchInfo.city
			: currentSearchInfo.city + `, ${currentSearchInfo.district}` || ""
	);
	const [placeHolder, setPlaceHolder] = useState(t("Вся Україна"));
	const getPlaces = useSelector(() => getHistoryPlaces);
	const clearPlaces = useSelector(() => getClearHistoryPlaces);
	const getSearchLocation = useSelector(() => getSettlementList);
	const setSearchLocation = useSelector(() => setSearchingLocationInfo);
	const setList = useSelector(() => setSettlementList);
	const places = useSelector(
		(state: StateType) => state.searchData.userPlacesHistory
	);
	const searchLocations = useSelector(
		(state: StateType) => state.settlementData.settlementsForSearch
	);
	const menuRef: React.Ref<any> = useRef(null);

	const handleChange = (value: string) => {
		setValue(value);
		if (value.length >= 3) {
			dispatch(getSearchLocation(value, 1));
		}
	};
	const handleClick = () => {
		setOpen(false);
		setOpenMenu(true);
		setPlaceHolder(t("Введіть назву міста"));
		dispatch(getPlaces());
	};
	const handleClearButton = (event: React.MouseEvent) => {
		event.preventDefault();
		dispatch(clearPlaces());
	};
	const handleClickItem = (item: SearchingLocations) => {
		setValue(
			item.district === "" ? item.city : item.city + `, ${item.district}`
		);
		setOpenMenu(false);
		dispatch(setSearchLocation(item));
		dispatch(setList(item));
	};
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			// Закрывать меню только если клик был снаружи области меню
			if (menuRef.current && !menuRef.current.contains(event.target)) {
				setOpenMenu(false);
			}
		};
		// Добавить слушателя событий при монтировании компонента
		document.addEventListener("click", handleClickOutside);
		// Очистить слушателя событий при размонтировании компонента
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, []);
	return (
		<div className={s.mainSearchLocation} ref={menuRef}>
			<div className={s.mainSearchLocationTop}>
				<div className={s.mainSearchLocationLeft}>
					<button type={"button"} onClick={() => setOpenMenu(!openMenu)}>
						<MainSearchSvgSelector id={"location"} />
					</button>
					<input
						className={s.mainSearchLocationInput}
						placeholder={placeHolder}
						value={value}
						onChange={({ currentTarget }) => handleChange(currentTarget.value)}
						onClick={() => handleClick()}
					/>
				</div>
				<div
					className={s.mainSearchLocationArrow}
					onClick={() => setOpenMenu(!openMenu)}
				>
					<span className={openMenu ? s.rotate : ""}>
						<MainSearchSvgSelector id={"arrow"} />
					</span>
				</div>
			</div>
			{openMenu && (
				<div className={s.mainSearchLocationMenu}>
					{places.length > 0 && searchLocations.length === 0 && (
						<div className={s.locationSelectContainer}>
							<div className={s.locationHistoryContainer}>
								<div className={s.locationHistoryTop}>
									<div className={s.recentlyLoc}>{t("Останні перегляди")}</div>
									<div className={s.recentlyClear}>
										<button
											onClick={(event) => handleClearButton(event)}
											className={s.clearButton}
										>
											{t("Очистити")}
										</button>
									</div>
								</div>
								<div className={s.locationHistoryList}>
									<ul className={s.locationHistoryMenu}>
										<li
											className={s.locationHistoryMenuElement}
											onClick={() =>
												handleClickItem({
													id: " ",
													city: t("Вся Україна"),
													district: "",
												})
											}
										>
											<div className={s.menuElIcon}>
												<MainSearchSvgSelector id={"locationTransparent"} />
											</div>
											<div className={s.menuElLocationName}>
												{t("Вся Україна")}
											</div>
										</li>
										{places.slice(0, 4).map((place: SearchingLocations) => (
											<li
												className={s.locationHistoryMenuElement}
												key={place.id}
												onClick={() => handleClickItem(place)}
											>
												<div className={s.menuElIcon}>
													<MainSearchSvgSelector id={"locationTransparent"} />
												</div>
												<div className={s.menuElLocationName}>{place.city}</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					)}
					{/* Searching now block*/}
					{searchLocations.length > 0 && value.length >= 3 && (
						<div className={s.locationSelectContainer}>
							<div className={s.locationSearchingContainer}>
								<ul className={s.searchingVariantsList}>
									<li
										className={s.searchingVariantsEl}
										onClick={() =>
											handleClickItem({
												id: " ",
												city: t("Вся Україна"),
												district: "",
											})
										}
									>
										<div className={s.cityCityArea}>{t("Вся Україна")}</div>
										<div className={s.district}> </div>
									</li>
									{searchLocations.map((item: SearchingLocations) => (
										<li
											className={s.searchingVariantsEl}
											key={item.id}
											onClick={() => handleClickItem(item)}
										>
											<div className={s.cityCityArea}>{item.city}</div>
											<div className={s.district}>{item.district}</div>
										</li>
									))}
								</ul>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default MainSearchLocation;
