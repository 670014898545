import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router";
import ScrollToTop from "react-scroll-to-top";
import { ActionCreator } from "redux";
import Footer from "./Components/Footer/Footer";
import MobileFooter from "./Components/Footer/MobileFooter";
import Header from "./Components/Header/Header";
import HeaderMobile from "./Components/Header/HeaderMobile";
import HeaderMobileCabinet from "./Components/Header/HeaderMobileCabinet";
import FullScreenImageGallery from "./Components/ImageGallery/FullScreenImageGallery";
import Main from "./Components/Main/Main";
import OutputAdMobileGallery from "./Components/OutputBoardAdComponents/MobileGallery/OutputAdMobileGallery";
import RedactionAdsCabinetMobile from "./Components/PersonalCabinet/RedactionAdsCabinet/RedactionAdsCabinetMobile";
import RegistrationAuthForms from "./Components/Registration&Auth/Registration&AuthForms";
import "./galleryStyles.css";
import CreateBoardAd from "./pages/CreateBoardAD/CreateBoardAD";
import OutputBoardAd from "./pages/OutputBordAD/OutputBoardAD";
import { getSEOInfo } from "./redux/pages_Reducer";
import { getSettlementByLocation } from "./redux/settlement_Reducer";
import { getUserStaticView, getUserView } from "./redux/user_Reducer";
import { Desktop, Mobile } from "./responsive";
import "./swiperStyles.css";
import { stateUserType } from "./types/reduxStoreTypes";

function App() {
	const [headerSee, setHeaderSee] = useState(true);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const dispatch: any = useDispatch();
	const userView: ActionCreator<any> = useSelector(() => getUserView);
	const getStatic = useSelector(() => getUserStaticView);
	const getLocationByCoordinates = useSelector(() => getSettlementByLocation);
	const getSeo: ActionCreator<any> = useSelector(() => getSEOInfo);
	const { i18n } = useTranslation();
	const [openRegAuthPopup, setOpenRegAuthPopup] = useState(false);
	const navigate = useNavigate();
	const openEnterDialog = () => {
		setOpenRegAuthPopup(true);
	};
	const handleClose = () => {
		setOpenRegAuthPopup(false);
	};
	useEffect(() => {
		const currentPath = window.location.pathname;

		// Проверяем условие и выполняем редирект при необходимости
		if (
			i18n.language === "uk" &&
			!currentPath.startsWith("/ua") &&
			!currentPath.startsWith("/user/set-password") &&
			!currentPath.startsWith("/create_ad")
		) {
			// Выполняем редирект на ту же страницу, но с добавлением /ua в начале URL
			navigate("/ua" + currentPath);
		}
	}, []);

	useMemo(() => {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const latitude = position.coords.latitude.toString();
					const longitude = position.coords.longitude.toString();

					dispatch(
						getLocationByCoordinates({
							longitude: longitude,
							latitude: latitude,
						})
					);
				},
				(error) => {
					console.error("Error getting location:", error);
				}
			);
		} else {
			console.log("Geolocation is not available in this browser.");
		}
	}, []);

	useEffect(() => {
		dispatch(getSeo());
	}, [auth, i18n.language]);

	useEffect(() => {
		if (
			(localStorage.getItem("JWTBago") && !auth) ||
			(sessionStorage.getItem("JWTBago") && !auth)
		) {
			dispatch(userView());
			dispatch(getStatic());
		}
		const checkStatic = () => {
			if (auth) {
				dispatch(getStatic());
			}
		};
		const interval = setInterval(checkStatic, 100000);
		const languageChangeHandler = () => {
			// in this function dispatch after language changed
			dispatch(userView());
			const url = i18n.language === "ru" ? "/" : "/ua";
			window.history.replaceState({}, "", url);
		};
		i18n.on("languageChanged", languageChangeHandler);
		return () => {
			i18n.off("languageChanged", languageChangeHandler);
			clearInterval(interval);
		};
	}, [auth, i18n.language, dispatch]); //images

	return (
		<>
			<Desktop>
				{openRegAuthPopup && (
					<RegistrationAuthForms
						dialogClose={handleClose}
						dialogOpen={openRegAuthPopup}
						loginOrRegistration={""}
					/>
				)}
			</Desktop>
			<Routes>
				<Route
					path={"/*"}
					element={
						<div onScroll={() => setHeaderSee(false)} className={"wrapper"}>
							<Routes>
								<Route
									path={"/personal_cabinet/*"}
									element={
										<>
											<Mobile>
												<HeaderMobileCabinet />
											</Mobile>
											<Desktop>
												<Header
													openEnterDialog={openEnterDialog}
													headerSee={headerSee}
												/>
											</Desktop>
										</>
									}
								/>
								<Route
									path={"*"}
									element={
										<>
											<Desktop>
												<Header
													openEnterDialog={openEnterDialog}
													headerSee={headerSee}
												/>
											</Desktop>
											<Mobile>
												<HeaderMobile />
											</Mobile>
										</>
									}
								/>
							</Routes>
							<Main setHeaderSee={setHeaderSee} />
							<Mobile>
								<MobileFooter />
							</Mobile>
							<Desktop>
								<Footer />
							</Desktop>
							<ScrollToTop
								top={2200}
								smooth
								color={"#FFD74A"}
								style={{
									backgroundColor: "#2B2A38",
									borderRadius: "12px",
									opacity: "0.5",
									bottom: "103px",
								}}
							/>
						</div>
					}
				/>
				<Route
					path={"/ua/*"}
					element={
						<div onScroll={() => setHeaderSee(false)} className={"wrapper"}>
							<Routes>
								<Route
									path={"/personal_cabinet/*"}
									element={
										<>
											<Mobile>
												<HeaderMobileCabinet />
											</Mobile>
											<Desktop>
												<Header
													openEnterDialog={openEnterDialog}
													headerSee={headerSee}
												/>
											</Desktop>
										</>
									}
								/>
								<Route
									path={"*"}
									element={
										<>
											<Desktop>
												<Header
													openEnterDialog={openEnterDialog}
													headerSee={headerSee}
												/>
											</Desktop>
											<Mobile>
												<HeaderMobile />
											</Mobile>
										</>
									}
								/>
							</Routes>
							<Main setHeaderSee={setHeaderSee} />
							<Mobile>
								<MobileFooter />
							</Mobile>
							<Desktop>
								<Footer />
							</Desktop>
							<ScrollToTop
								top={2200}
								smooth
								color={"#FFD74A"}
								style={{
									backgroundColor: "#2B2A38",
									borderRadius: "12px",
									opacity: "0.5",
									bottom: "103px",
								}}
							/>
						</div>
					}
				/>
				{/* <Route path='/static-page/:slug' element={<StaticPageTemplate/>}/> */}
				{/* <Route path='/static-page/:slug' element={
                        <>
                            <Desktop>
                                <Header openEnterDialog={openEnterDialog} headerSee={true}/>
                            </Desktop>
                            <Mobile>
                                <HeaderMobile/>
                            </Mobile>
                            <StaticPageTemplate/>
                            <Mobile>
                                <MobileFooter/>
                            </Mobile>
                            <Desktop>
                                <Footer/>
                            </Desktop>
                        </>
                    }/>*/}
				<Route
					path={"/o/:itemId/gallery_chat/:itemIndex"}
					element={
						<>
							<Mobile>
								<OutputAdMobileGallery />
							</Mobile>
							<Desktop>
								<FullScreenImageGallery />
							</Desktop>
						</>
					}
				/>
				<>
					<Route
						path={"/personal_cabinet/ads/redaction_ads/*"}
						element={auth ? <RedactionAdsCabinetMobile /> : <></>}
					/>
					<Route
						path={`${i18n.language === "ru" ? "" : "/ua/"}create_board_ad_prev_view`}
						element={auth ? <OutputBoardAd type={"perView"} /> : <></>}
					/>
					<Route
						path={`/create_ad/*`}
						element={auth ? <CreateBoardAd /> : <></>}
					/>
					<Route
						path={`${i18n.language === "ru" ? "" : "/ua/"}create_ad/:noticeId`}
						element={auth ? <CreateBoardAd /> : <></>}
					/>
				</>
			</Routes>
		</>
	);
}

export default App;
