import React, { useState, useEffect } from "react";
import s from "./Registration&AuthForms.module.css";
import { Link } from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import FastEnter from "./FastEnter";
import FormRegAuth from "./FormReg&Auth";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StateType } from "../../redux/redux-store";
import ApprovePhonePopup from "../../Auth&RegNewPopups/ApprovePhonePopup";
import ArrowsAndCross from "../../common/ArrowsAndCross/ArrowsAndCross";

type PropsType = {
	dialogOpen: boolean;
	loginOrRegistration: string;
	dialogClose: () => void;
};

const RegistrationAuthForms: React.FC<PropsType> = ({
	dialogClose,
	dialogOpen,
	loginOrRegistration,
}) => {
	const { t } = useTranslation();
	const [isAuth, setIsAuth] = useState(true);
	const openApproved = useSelector(
		(state: StateType) => state.userData.openApprovePhonePopup
	);
	const [errorApple, setErrorApple] = useState("");

	useEffect(() => {
		if (loginOrRegistration === "login") {
			setIsAuth(true);
		} else if (loginOrRegistration === "registration") {
			setIsAuth(false);
		}
	}, [loginOrRegistration]);

	return (
		<div
			className={s.modalWrapper} /*onClick={(e)=>{
                if (e.target === e.currentTarget) {
                    dialogClose()
                }
            }}*/
		>
			{openApproved ? (
				<ApprovePhonePopup closeModal={dialogClose} />
			) : (
				<div className={s.dialogContainer}>
					<div style={{ height: !isAuth ? "525px" : "450px" }}>
						<div className={s.authFormContainer}>
							<div className={s.formTitle}>
								<div className={s.formTitlePanel}>
									{!openApproved && (
										<>
											<div className={s.titlePanelButton}>
												<h5
													onClick={() => setIsAuth(true)}
													className={isAuth ? s.active : ""}
												>
													{t(`Вхід`)}
												</h5>
												<h5
													onClick={() => setIsAuth(false)}
													className={!isAuth ? s.active : ""}
												>
													{t(`Реєстрація`)}
												</h5>
											</div>
											<span
												className={s.titlePanelButtonCloseCross}
												onClick={() => dialogClose()}
											>
												<ArrowsAndCross id={"closeCross"} />
											</span>
										</>
									)}
								</div>
								{!isAuth ? (
									<div className={s.politics}>
										<p>
											{t(`Реєструючись, Ви погоджуєтесь з `)}
											<Link
												onClick={() => dialogClose()}
												to={"/static-page/politika-konfidentsialnosti"}
											>
												{t(`Політикою Конфіденційності`)}
											</Link>{" "}
											{t(`та `)}
											<Link
												onClick={() => dialogClose()}
												to={"/static-page/polzovatelskoye-soglasheniye"}
											>
												{t(`Угодою користувача`)}{" "}
											</Link>
										</p>
									</div>
								) : (
									<div className={s.politics}>
										<p>{t(`Під час авторизації, Ви погоджуєтесь з `)}</p>
										<Link
											onClick={() => dialogClose()}
											to={"/static-page/polzovatelskoye-soglasheniye"}
										>
											{t(`Угодою користувача`)}
										</Link>
									</div>
								)}
							</div>

							<div className={s.formContent}>
								<FormRegAuth
									isAuth={isAuth}
									setIsAuth={setIsAuth}
									closeModal={dialogClose}
									errorApple={errorApple}
								/>
								<div className={s.borderElement}>
									<span>{t(`або`)}</span>
								</div>
								<FastEnter
									closeModal={dialogClose}
									errorApple={errorApple}
									setErrorApple={setErrorApple}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default RegistrationAuthForms;
