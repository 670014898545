import React, { useState, useEffect } from "react";
import s from "./AdCard.module.css";
import NoRegisterSvgSelector from "./NoRegisterSvgSelector";
import { useTranslation } from "react-i18next";
import RegistrationAuthForms from "../Registration&Auth/Registration&AuthForms";
import handleSortPhrases from "./sortPhrases";
import {
	colorArray,
	noRegisterPhrasesArray,
} from "../../data_arrays/BannersData";
interface InterfacePhrasesArray {
	text: string;
	author: string;
}

const NoRegisterAdCard = () => {
	const { t } = useTranslation();

	const [openRegAuthPopup, setOpenRegAuthPopup] = useState(false);
	const [loginOrRegistration, setloginOrRegistration] = useState("");
	const [randomColor, setRandomColor] = useState("");
	const [randomPhrases, setRandomPhrases] =
		useState<InterfacePhrasesArray | null>(null);

	useEffect(() => {
		const sortResoult = handleSortPhrases(
			noRegisterPhrasesArray,
			"noAuthBagoPhrasesArray"
		);
		setRandomPhrases(sortResoult);

		const randomColorIndex = Math.floor(Math.random() * colorArray.length);
		setRandomColor(colorArray[randomColorIndex]);
	}, []);

	const openEnterDialog = () => {
		setOpenRegAuthPopup(true);
	};
	const handleClose = () => {
		setOpenRegAuthPopup(false);
	};

	const handleOpenLogin = () => {
		setloginOrRegistration("login");
		openEnterDialog();
	};

	const handleOpenRegistration = () => {
		setloginOrRegistration("registration");
		openEnterDialog();
	};

	return (
		<>
			{randomColor && (
				<div className={`${s.container} __container`}>
					<div style={{ background: `#${randomColor}`, borderRadius: "5px" }}>
						<div className={s.cardContainer}>
							<div className={s.logo}>
								<NoRegisterSvgSelector id={"logoBago"} />
							</div>
							<div className={s.content}>
								<div className={s.contentDescriptionWrap_noRegister}>
									<p className={s.contentDescription}>
										{randomPhrases && t(`${randomPhrases?.text}`)}
									</p>
								</div>
								<div className={s.contentAuthorWrap}>
									<p className={s.contentDescriptionAuthor}>
										{randomPhrases && t(`${randomPhrases?.author}`)}
									</p>
								</div>
							</div>
							<div className={s.buttons}>
								<button className={s.buttonEnter} onClick={handleOpenLogin}>
									{t("вхід")}
								</button>
								<button
									className={s.registration}
									onClick={handleOpenRegistration}
								>
									{t("Реєстрація")}
								</button>
							</div>
						</div>
					</div>
					{openRegAuthPopup && (
						<RegistrationAuthForms
							dialogClose={handleClose}
							dialogOpen={openRegAuthPopup}
							loginOrRegistration={loginOrRegistration}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default NoRegisterAdCard;
