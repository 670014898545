import React from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import s from "../CreateBoardAD.module.css";
import { useDispatch, useSelector } from "react-redux";
import { getSettlementList } from "../../../redux/settlement_Reducer";
import { StateType } from "../../../redux/redux-store";
import ArrowsAndCross from "../../../common/ArrowsAndCross/ArrowsAndCross";

type categorySelectProps = {
	values: { settlement_value: string | number; settlement_label: string };
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	setStatus: any;
};

const ChangeSettlementSelect = ({
	values,
	setFieldValue,
	setStatus,
}: categorySelectProps) => {
	const { t } = useTranslation();
	const dispatch: any = useDispatch();
	const getSettlement = useSelector(() => getSettlementList);
	const resultSettlement = useSelector(
		(state: StateType) => state.settlementData.settlements
	);
	const userLocation = useSelector(
		(state: StateType) => state.settlementData.settlementByLocation
	);
	return (
		<Select
			isClearable={false}
			isSearchable
			options={[...userLocation, ...resultSettlement]}
			value={[
				{
					value: values.settlement_value || "0",
					label:
						values.settlement_label || t("Введіть назву міста українською"),
				},
			]}
			components={{
				IndicatorSeparator: () => null,
				DropdownIndicator: ({ selectProps }) => (
					<span
						className={s.dropdownArrow}
						style={{
							transform: selectProps.menuIsOpen ? "rotate(180deg)" : "",
							/* marginTop: selectProps.menuIsOpen ? "5px" : "",*/
							transition: "400ms",
						}}
					>
						<ArrowsAndCross id={"arrowForSelect"} />
					</span>
				),
			}}
			/*placeholder={`${t('Введіть назву міста на рідній мові')}...`}*/
			styles={{
				control: (baseStyles, state) => ({
					...baseStyles,
					padding: "0 15px 0 18px ",
					borderColor: state.menuIsOpen ? "#E7E7E7" : "#E7E7E7",
					borderBottom: state.menuIsOpen
						? "2px solid #00A9CE"
						: "1px solid #E7E7E7",
					//borderBottomColor:state.isFocused ?"" : "00A9CE",
					boxShadow: "none",
					maxWidth: "100%",
					maxHeight: "40px",
					fontSize: "16px",
					borderRadius: "4px",
					"&:hover": {
						borderBottom: state.menuIsOpen
							? "2px solid #00A9CE"
							: "1px solid #E7E7E7",
					},
				}),
				singleValue: (styles, state) => {
					return {
						...styles,
						borderRadius: "4px",
						fontFamily: "var(--myMainFontRegular)",
						fontSize: "16px",
						color: "#727272",
					};
				},
				input: (styles, state) => {
					return {
						...styles,
						borderColor: "red",
						margin: "0",
						padding: "0",
					};
				},
				option: (base) => ({
					...base,
					boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.06)",
					borderRadius: "5px",
				}),
			}}
			onInputChange={(newValue) => {
				setStatus({});
				if (newValue.length >= 3) {
					dispatch(getSettlement(newValue));
				}
			}}
			onChange={(newValue) => {
				setStatus({});
				setFieldValue("settlement_value", newValue?.value);
				setFieldValue("settlement_label", newValue?.label);
			}}
		/>
	);
};

export default ChangeSettlementSelect;
