import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useClickOutside } from "../../../../CustomHooks/useClickOutside";
import { buyService, offService } from "../../../../redux/notice_Reducer";
import { StateType } from "../../../../redux/redux-store";
import { formattingPrice, testActualityService } from "../../../../supporting";
import { NoticeDataForModal, NoticeType } from "../../../../types/noticeTypes";
import OnlyDay from "../../../TimeAndDate/OnlyDay";
import PublishedMonthYear from "../../../TimeAndDate/PublishedMonthYear";
import PersonalCabinetSvgSelector from "../../PersonalCabinetSvgSelector";
import s from "./MyAdsCardRightFuncPanel.module.css";

type Props = {
	setOpenAutoRenewalSettings: (dataForModal: NoticeDataForModal) => void;
	setPitchToTopSettings: (b: boolean) => void;
	setVisibleAdsOption: (b: boolean) => void;
	setRaiseAd?: (b: boolean) => void;
	type: "mobile" | "desc";
	notice: NoticeType;
};

const MyAdsCardRightFuncPanel = ({
	setOpenAutoRenewalSettings,
	notice,
	setPitchToTopSettings,
	setVisibleAdsOption,
	setRaiseAd,
	type,
}: Props) => {
	const dispatch: any = useDispatch();
	const buy = useSelector(() => buyService);
	const { t } = useTranslation();
	const [autoRenewal, setAutoRenewal] = useState(false);
	const [pitchToTop, setPitchToTop] = useState(false);
	const [pitchByNow, setPitchByNow] = useState(false);
	//const giveMePriceList = useSelector(() => getPriceList)
	const touchOffService = useSelector(() => offService);
	const balance = useSelector(
		(state: StateType) => state.userData.userStatic.totalBalance
	);
	//const prises = useSelector((state: StateType) => state.priceData.prices);
	const oneTimeUp = useSelector(
		(state: StateType) => state.priceData.oneTimeUp
	);
	const disabledButton = balance < (oneTimeUp?.price ? +oneTimeUp.price : 0);

	/*useEffect(() => {
        if(!prises){
            dispatch(giveMePriceList())
        }
    }, [prises,i18n.language]);*/

	const handleClickOffAutoRenewal = (id: number | null) => {
		if (id) {
			dispatch(touchOffService(id));
		}
	};
	const handleOpenAutoRenewal = () => {
		setAutoRenewal(true);
		setPitchToTop(false);
	};
	const handleClosePopups = () => {
		setAutoRenewal(false);
		setPitchToTop(false);
	};
	const outside = useClickOutside<HTMLDivElement>(handleClosePopups);

	const handleClosePitchBuyNow = () => {
		setPitchByNow(false);
	};
	const handlePayService = (
		id: number | null,
		value: number | null,
		handleClose: () => void
	) => {
		if (id && value)
			dispatch(buy(+id, +value, handleClose, oneTimeUp?.price, notice.name));
	};
	const openModalAutoRenewal = () => {
		setOpenAutoRenewalSettings({
			balance: balance,
			price: notice.price,
			img: notice.noticeImages ? notice.noticeImages[0] : "",
			name: notice.name,
			settlement: notice.short_settlement_name,
			id: notice.id?.toString() ?? "",
		});
	};

	// const handlePayService = (id: number | null, value: number | null) => {
	// 	if (id && value) dispatch(buy(+id, +value))
	// }

	return (
		<div className={s.activeLeftOptionContainer}>
			<div className={s.optionsLeft}>
				<div className={s.optionsLeftButtons}>
					{/* {type === 'desc' && (
						<button
							className={s.buttonOneUp}
							onClick={() => setPitchByNow(!pitchByNow)}
						>
							{!pitchByNow
								? t('Підняти за') +
									' ' +
									formattingPrice(oneTimeUp?.price ?? '') +
									' ' +
									'грн'
								: t('Відмінити')}
						</button>
					)} */}
					{pitchByNow && (
						<div className={s.pitchByNow}>{t("Підняти 1 раз")}</div>
					)}
					{!pitchByNow && (
						<>
							<button
								onClick={() =>
									type === "mobile"
										? setRaiseAd?.(true)
										: setPitchByNow(!pitchByNow)
								}
							>
								{" "}
								{t("Підняти за") +
									" " +
									formattingPrice(oneTimeUp?.price ?? "") +
									" " +
									"грн"}
							</button>
							<button
								className={
									testActualityService(notice.auto_lift_at)
										? s.activeRenewal
										: ""
								}
								onClick={() => {
									if (testActualityService(notice.auto_lift_at)) {
										handleOpenAutoRenewal();
									} else {
										openModalAutoRenewal();
									}
								}}
							>
								{t("автопідняття")}
							</button>
							{autoRenewal && testActualityService(notice.auto_lift_at) && (
								<div
									ref={outside}
									onClick={() => setAutoRenewal(false)}
									className={s.autoRenewalPopup}
								>
									<div className={s.autoRenewalPopupTop}>
										<span onClick={() => handleOpenAutoRenewal()}>
											{t("автопідняття")}
										</span>
										<PersonalCabinetSvgSelector id={"selectArrow"} />
									</div>
									<div className={s.autoRenewalPopupMain}>
										{testActualityService(notice.auto_lift_at) ? (
											<>
												1 раз в {notice.auto_lift_days} {t("днів")} <br />
												{t("наст")}: <OnlyDay seconds={notice.auto_lift_at} />{" "}
												<PublishedMonthYear
													seconds={notice?.auto_lift_at ?? null}
												/>
											</>
										) : (
											<>{t("Вимкнено")}</>
										)}
									</div>
									<div
										onClick={() => handleClickOffAutoRenewal(notice.id)}
										className={`${s.autoRenewalPopupBottom} ${s.offButton}`}
									>
										{t("Викл")}.
									</div>
								</div>
							)}
							<button
								className={
									testActualityService(notice.top_at) ? s.activeTop : ""
								}
								onClick={() => {
									if (testActualityService(notice.top_at)) {
										setPitchToTop(true);
									} else {
										setPitchToTopSettings(true);
									}
								}}
							>
								+ ТОП
							</button>
							{pitchToTop && testActualityService(notice.top_at) && (
								<div
									ref={outside}
									onClick={() => setPitchToTop(false)}
									className={s.pitchToTopPopup}
								>
									<div className={s.pitchToTopPopupTitle}>
										{t("оголошення")} в ТОП
									</div>
									<div className={s.pitchToTopPopupInfo}>
										{testActualityService(notice.top_at)
											? t("Залишилось") +
												" " +
												notice?.top_days +
												" " +
												t("днів")
											: t("Вимкнено")}
									</div>
								</div>
							)}
						</>
					)}
				</div>
				<div className={s.optionsLeftBalance}>
					<span>Ваш баланс: {balance} грн</span>
				</div>
			</div>
			<div className={s.optionsRight}>
				<div className={s.optionsRightTop}>
					<span onClick={() => setVisibleAdsOption(false)}>
						<PersonalCabinetSvgSelector id={"closeCross"} />
					</span>
					{type === "desc" && pitchByNow && (
						<span style={{ color: pitchByNow ? "#3D3D3D" : "" }}>
							{pitchByNow ? formattingPrice(oneTimeUp?.price ?? "") : "0"} грн
						</span>
					)}
				</div>
				<div className={s.optionsRightBottom}>
					{type === "desc" && pitchByNow && (
						<button
							// disabled={disabledButton}
							style={{
								color: pitchByNow ? "#7BBC23" : "",
								borderColor: pitchByNow ? "#7BBC23" : "",
							}}
							onClick={() =>
								handlePayService(
									notice?.id,
									oneTimeUp?.id ?? null,
									handleClosePitchBuyNow
								)
							}
						>
							{t("Оплатити")}
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default MyAdsCardRightFuncPanel;
