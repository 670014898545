import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { StateType } from "../../redux/redux-store";
import s from "./Footer.module.css";
import FooterSvgSelector from "./FooterSvgSelector";

const useStyles = makeStyles(() => ({
	badge: {
		width: "12px",
		height: "12px",
		right: 0,
		top: 3,
		padding: "3px",
		color: "black",
		backgroundColor: "#FFD74A",
		fontSize: "8px",
	},
}));

const MobileFooter: React.FC = () => {
	//const classes = useStyles();
	const userView = useSelector((state: StateType) => state.userData.user);
	const location = useLocation();
	const isCreateAdPage = location.pathname === "/create_ad";
	const [currentPath, setCurrentPath] = useState<string>(location.pathname);
	const { i18n, t } = useTranslation();
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location.pathname]);

	const renderMenuItem = (
		id: string,
		path: string,
		label: string,
		extraClass = ""
	) => (
		<li className={`${s.menuNavElement} ${extraClass}`}>
			<Link to={path}>
				<span className={s.menuNavElementSvg}>
					<FooterSvgSelector id={id} />
				</span>
				<span className={s.menuNavElementName}>{t(label)}</span>
			</Link>
		</li>
	);

	if (isCreateAdPage) {
		return null;
	}

	return (
		<div className={`${s.mobileFooterContainer} mui-fixed`}>
			<ul className={s.menuNav}>
				{renderMenuItem(
					"home",
					currentLanguage,
					"Головна",
					currentPath === "/" || currentPath === "/ua"
						? s.menuNavElementActive
						: ""
				)}
				{renderMenuItem(
					"heart",
					userView?.id
						? "/personal_cabinet/favorite"
						: `${currentLanguage}/registration_auth`,
					"Обране"
				)}
				{renderMenuItem(
					"create",
					userView?.id ? "/create_ad" : `${currentLanguage}/registration_auth`,
					"Створити"
				)}
				<li className={s.menuNavElement}>
					<div className={s.menuNavElementDisabled}>
						<span className={s.menuNavElementSvg}>
							{/* <Badge
								classes={{ badge: classes.badge }}
								overlap='rectangular'
								badgeContent={12}
							> */}
							<FooterSvgSelector id={"message"} />
							{/* </Badge> */}
						</span>
						<span className={s.menuNavElementName}>{t("Повідомлення")}</span>
					</div>
				</li>
				{renderMenuItem(
					"profile",
					userView?.id
						? "/personal_cabinet"
						: `${currentLanguage}/registration_auth`,
					"Профіль"
				)}
			</ul>
		</div>
	);
};

export default MobileFooter;
