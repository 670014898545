import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Props } from "react-select";
import mockListing from "../../../images/listing-card-mobile.svg";
import { setClearFiltersList } from "../../../redux/category_Reducer";
import { setImagesList } from "../../../redux/notice_Reducer";
import { StateType } from "../../../redux/redux-store";
import { setPhoneEnterPopup } from "../../../redux/user_Reducer";
import { stateUserType } from "../../../types/reduxStoreTypes";
import s from "../ProductCard/ProductCardHorizontalMobile.module.css";

const AddListingCardRowMobile = (props: Props) => {
	const { i18n, t } = useTranslation();
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const dispatch = useDispatch();
	const location = useLocation();
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const userView = useSelector((state: StateType) => state.userData.user);
	const clearFilters = useSelector(() => setClearFiltersList);
	const segmentsArray = location.pathname?.split("/").filter(Boolean);
	const handleEnterPopup = useSelector(() => setPhoneEnterPopup);
	const [openRegAuthPopup, setOpenRegAuthPopup] = useState(false);
	const decodedSearchingName = useMemo(() => params.get("search"), [params]);

	const openEnterDialog = () => {
		setOpenRegAuthPopup(true);
	};
	const handleClose = () => {
		setOpenRegAuthPopup(false);
	};

	const clearInputs = () => {
		dispatch(setImagesList([]));
		dispatch(clearFilters());
	};

	const onClickOnCard = () => {
		clearInputs();
		if (auth && userView.is_approve_phone !== 1) {
			dispatch(handleEnterPopup(true));
		} else if (auth && userView.is_approve_phone === 1) {
			if (
				segmentsArray?.length > 0 &&
				(segmentsArray[0] === "create_ad" || segmentsArray[1] === "create_ad")
			) {
				alert(
					t(
						"Оголошення, яке ви розпочали подавати," +
							" ще не було опубліковано. Бажаєте повернутися до цього оголошення?"
					)
				);
			} else {
				navigate(`/create_ad`);
			}
		}
	};

	return (
		<div className={s.horizontalCardBlock}>
			<div className={s.horizontalCardImageBlock}>
				<img src={mockListing} alt={"listing"} loading="lazy" />
			</div>
			<div className={s.horizontalCardContentBlock}>
				<div
					onClick={() => {
						!auth && openEnterDialog();
					}}
					className={s.addAD}
				>
					<h2>
						{decodedSearchingName === ""
							? t("Додати оголошення")
							: t("Додати в цю рубрику оголошення")}
					</h2>
					<Link
						to={auth && userView.is_approve_phone === 1 ? `/create_ad` : ""}
						onClick={onClickOnCard}
					>
						<h3>+ {t(`оголошення`)}</h3>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default AddListingCardRowMobile;
