import React from "react";
import s from "./PersonalCabinet.module.css";
import PersonalCabinetNavBar from "../../Components/PersonalCabinet/PersonalCabinetNavBar/PersonalCabinetNavBar";
import "./PersonalCabinet.css";
import PersonalCabinetAds from "../../Components/PersonalCabinet/My Ads/PersonalCabinetAds";
import { Route, Routes } from "react-router";
import PersonalCabinetSettings from "../../Components/PersonalCabinet/PersonalCabinetSettings/PersonalCabinetSettings";
import PersonalCabinetOrders from "../../Components/PersonalCabinet/PersonalCabinetOrders";
import PersonalCabinetPurchases from "../../Components/PersonalCabinet/PersonalCabinetPurchases";
import PersonalCabinetBalance from "../../Components/PersonalCabinet/PersonalCabinetBalance";
import MyFavorite from "../../Components/PersonalCabinet/My Favorite/MyFavorite";

import MyRecentlyWatched from "../../Components/PersonalCabinet/MyRecentlyWatchedMobile/MyRecentlyWatched";
import Advertising from "../../Components/Сommercial/Advertising";

const PersonalCabinet: React.FC = () => {
	return (
		<div className={s.personalCabinetWrapper}>
			<div className={`${s.personalCabinetContainer} __container `}>
				<div className={s.cabinetNavMenu}>
					<PersonalCabinetNavBar />
					<div className={s.adverts}>
						<Advertising idAD={7} />
					</div>
					<div className={s.adverts}>
						<Advertising idAD={8} />
					</div>
				</div>
				<div className={s.cabinetContent}>
					<Routes>
						<Route path={"settings"} element={<PersonalCabinetSettings />} />
						<Route path={"ads/*"} element={<PersonalCabinetAds />} />
						<Route path={"orders/*"} element={<PersonalCabinetOrders />} />
						<Route
							path={"purchases/*"}
							element={<PersonalCabinetPurchases />}
						/>
						<Route path={"balance/*"} element={<PersonalCabinetBalance />} />
						<Route path={"favorite/*"} element={<MyFavorite />} />
						<Route
							path={"recently_watched/*"}
							element={<MyRecentlyWatched />}
						/>
					</Routes>
				</div>
			</div>
		</div>
	);
};

export default PersonalCabinet;
