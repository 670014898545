import { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import s from "./SEO.module.css";

type Props = {
	seoTextHtml: string;
};

// const HomeSeo = ({seoTextHtml}:Props) => {
//     const {t} = useTranslation()
//     const [isExpanded, setExpanded] = useState(false);
//     const maxLength = 1400;
//     let firstHalf = "";
//     let secondHalf = "";

//     if (seoTextHtml.length > maxLength) {
//         const firstSpaceIndex = seoTextHtml.lastIndexOf(' ', maxLength); // ищем последний пробел перед 1400 символами

//         if (firstSpaceIndex !== -1) {
//             firstHalf = seoTextHtml.slice(0, firstSpaceIndex);
//             secondHalf = seoTextHtml.slice(firstSpaceIndex + 1); // начинаем со следующего символа после пробела
//         } else {
//             // Если пробел не найден, разделение по символам
//             firstHalf = seoTextHtml.slice(0, maxLength);
//             secondHalf = seoTextHtml.slice(maxLength);
//         }
//     } else {
//         firstHalf = seoTextHtml;
//     }
//     return (
//         <div className={`${s.homeSeoContainer} __container`}>

//             <div className={s.seoContent} dangerouslySetInnerHTML={{ __html: firstHalf }}/>
//             {isExpanded &&
//             <div className={`${s.seoContentSecond}`} dangerouslySetInnerHTML={{ __html: secondHalf }}/>}
//             {seoTextHtml.length > 1400 &&
//             <div onClick={()=>setExpanded(!isExpanded)} className={s.seoBottomPanel}>
//                 {isExpanded ? t("Читати меньше") :t("Читати більше") }...
//             </div>}
//         </div>
//     );
// };

const HomeSeo = ({ seoTextHtml }: Props) => {
	const { t } = useTranslation();
	const [isExpanded, setExpanded] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const contentRef = useRef<HTMLDivElement>(null);

	const test = `<p>Ми вдячні Вам за те, що Ви з нами!</p>
    <p>Шановні користувачі, сайт знаходиться в розробці, в даний момент проводяться доналаштування та тестування. Але Ви можете розміщувати свої оголошення та намагатися користуватися доступними функціями. Всі Ваші оголошення будуть збережені та опубліковані.</p>`;

	const maxCollapsedHeight = 140; // Налаштуйте це значення за потреби

	useLayoutEffect(() => {
		const contentEl = contentRef.current;
		if (contentEl) {
			const contentHeight = contentEl.scrollHeight;
			if (contentHeight > maxCollapsedHeight) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		}
	}, [seoTextHtml, maxCollapsedHeight]);

	return (
		<div className={`${s.homeSeoContainer} __container`}>
			<div
				ref={contentRef}
				className={s.seoContent}
				style={{
					maxHeight: isExpanded ? "none" : `${maxCollapsedHeight}px`,
					overflow: "hidden",
				}}
				dangerouslySetInnerHTML={{ __html: seoTextHtml }}
			/>
			{showButton && (
				<div
					onClick={() => setExpanded(!isExpanded)}
					className={s.seoBottomPanel}
				>
					{isExpanded ? `${t("Згорнути")} ᨈ` : `${t("Читати далі")} ᨆ`}
				</div>
			)}
		</div>
	);
};

export default HomeSeo;
