import React from "react";
import s from "../PersonalCabinetAccordion/PersonalCabinetAccordion.module.css";
import PersonalCabinetSvgSelector from "../PersonalCabinetSvgSelector";

type PropsType = {
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => void;
	values: {
		viber: null | number;
		telegram: null | number;
		whatsapp: null | number;
	};
};

const MessengersIcons = ({ setFieldValue, values }: PropsType) => {
	return (
		<div className={s.messengerIcons}>
			<span onClick={() => setFieldValue("viber", values.viber === 1 ? 2 : 1)}>
				<PersonalCabinetSvgSelector
					id={"viber"}
					fill={
						values.viber === 1 ? `#7D3DAF` : values.viber === 0 ? "#BDBDBD" : ""
					}
				/>
			</span>
			<span
				onClick={() => setFieldValue("telegram", values.telegram === 1 ? 2 : 1)}
			>
				<PersonalCabinetSvgSelector
					id={"telegram"}
					fill={values.telegram === 1 ? `#2da0d1` : ""}
				/>
			</span>
			<span
				onClick={() => setFieldValue("whatsapp", values.whatsapp === 1 ? 2 : 1)}
			>
				<PersonalCabinetSvgSelector
					id={"whatsApp"}
					fill={values.whatsapp === 1 ? `#17970f` : ""}
				/>
			</span>
		</div>
	);
};

export default MessengersIcons;
