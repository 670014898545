import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
	getSearchCategories,
	setSearchingNoticeName,
} from "../../redux/search_Reducer";
import { stateUserType } from "../../types/reduxStoreTypes";
import { userTypes } from "../../types/userTypes";
import NavBarSvgSelector from "../NavBar/NavBarSvgSelector";
import s from "./HeaderMobile.module.css";
import HeaderSvg from "./HeaderSvg";
import { InputComponent } from "./Input";
import MainSearch from "../MainSearch/MainSearch";
import { Mobile } from "../../responsive";

const HeaderMobile = () => {
	const userView: userTypes = useSelector((state: any) => state.userData.user);
	const auth = useSelector((state: stateUserType) => state.userData.isAuth);
	const { t, i18n } = useTranslation();
	const dispatch: any = useDispatch();
	const getSearch = useSelector(() => getSearchCategories);
	const setSearchName = useSelector(() => setSearchingNoticeName);
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const searchQuery = params.get("search");
	const [currentValue, setCurrentValue] = useState(
		searchQuery ? searchQuery : ""
	);
	const [submit, setSubmit] = useState(false);
	const location = useLocation();
	const [isShowAvatar, setIsShowAvatar] = useState(false);
	const [currentPath, setCurrentPath] = useState("");
	const [openSearch, setOpenSearch] = useState<boolean>(false);
	const currentLanguage = i18n.language === "ru" ? "" : "/ua";

	const debounced = useDebouncedCallback(
		// function
		(value) => {
			dispatch(getSearch(value));
		},
		// delay in ms
		300
	);
	useEffect(() => {
		setCurrentPath(location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		setCurrentValue(searchQuery ? searchQuery : "");
	}, [searchQuery]);

	useEffect(() => {
		if (submit) {
			navigate(
				`${i18n.language === "uk" ? "/ua" : ""}/search_result?search=${
					currentValue.length !== 0 ? currentValue : ""
				}`
			);
			setSubmit(false);
		}
	}, [submit]);

	const previousUrl = useRef(location.pathname + location.search); // Хранение предыдущего URL

	useEffect(() => {
		const currentUrl = location.pathname + location.search;
		if (previousUrl.current !== currentUrl) {
			setOpenSearch(false);
			previousUrl.current = currentUrl; // Обновление предыдущего URL
		}
	}, [location]);
	useEffect(() => {
		const intervalId = setInterval(() => {
			setIsShowAvatar((prevShowAvatar) => !prevShowAvatar);
		}, 30000);

		return () => clearInterval(intervalId);
	}, []);

	const handleChangeInput = (event: any) => {
		//setOpen(true)
		setCurrentValue(event?.target.value);
		if (event?.target.value.length >= 3) {
			// setPopupType("search")
			//dispatch(getSearch(event?.target.value))
			debounced(event?.target.value);
		}
	};
	const handleFocusInput = (letters: string) => {
		setOpenSearch(true);
		if (letters.length < 3) {
			// setPopupType("history")
		}

		// setOpen(true)
	};

	const handleSendResult = () => {
		dispatch(setSearchName(currentValue));
		setSubmit(true);
	};

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === "Enter") {
			handleSendResult();
		}
	};

	const handleClickInput = () => {
		setOpenSearch(true);
	};
	const handleNavigate = () => {
		if (auth) {
			if (isShowAvatar) {
				navigate("/personal_cabinet");
			} else {
				navigate(i18n.language === "uk" ? "/ua" : "");
			}
		} else {
			navigate(i18n.language === "uk" ? "/ua" : "");
		}
	};

	return (
		<>
			{currentPath === `${currentLanguage}/registration_auth` ? (
				<></>
			) : (
				<>
					<div className={`${s.mobileHeaderWrap} mui-fixed`}>
						<div className={s.mobileHeaderContainer}>
							<InputComponent
								placeholder={t("Шукати на BAGO")}
								onClick={handleClickInput}
								value={currentValue}
								onChange={handleChangeInput}
								onFocus={() => handleFocusInput(currentValue)}
								onKeyDown={handleKeyDown}
							/>

							<div className={s.mobileHeaderIconblock} onClick={handleNavigate}>
								{auth ? (
									<>
										{isShowAvatar ? (
											<>
												{userView?.photo?.photoUrl ? (
													<div className={s.mobileHeaderAvatarBlock}>
														{userView?.photo?.photoUrl && (
															<img
																alt={"avatar"}
																src={userView?.photo?.photoUrl}
																className={s.mobileHeaderAvatar}
															/>
														)}
													</div>
												) : (
													<div className={s.mobileHeaderAvatarBlock}>
														<NavBarSvgSelector id="profile" />
													</div>
												)}
											</>
										) : (
											<Link to={"/"}>
												<HeaderSvg id="logoBagoMobile" />
											</Link>
										)}
									</>
								) : (
									<Link to={"/"}>
										<HeaderSvg id="logoBagoMobile" />
									</Link>
								)}
							</div>
						</div>
					</div>
					<Mobile>
						{openSearch && (
							<MainSearch open={openSearch} setOpen={setOpenSearch} />
						)}
					</Mobile>
				</>
			)}
		</>
	);
};

export default HeaderMobile;
