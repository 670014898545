export const colorArray = [
	"FBF0E7",
	"FEE5D4",
	"FFFBEF",
	"FFF7E1",
	"FDFED4",
	"E9FADF",
	"D3EBDD",
	"F1E5FF",
	"E2F5FF",
	"D3F9FE",
	"DFEBFA",
	"E2ECFF",
	"EBF2FF",
	"E4FDF8",
	"E5F4EB",
];

export const registerPhrasesArray = [
	{
		id: 1,
		text: "Зараз – ти працюєш на час. Завтра – час працює на тебе. Дій!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 2,
		text: "Мета – це мрія з кінцевим терміном виконання.",
		author: "Діана Сайрес",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 3,
		text: "Мета без плану – лише бажання.",
		author: "Антуан де Сент-Екзюпері",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 4,
		text: "Мета – це якір, який допомагає зосередитися на майбутньому.",
		author: "Тоні Роббінс",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 5,
		text: "Якщо у вас немає мети, ви ніколи не потрапите в ціль.",
		author: "Зіг Зіглар",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 6,
		text: "Люди з метою живуть осмисленим життям.",
		author: "Маркус Туллій Цицерон",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 7,
		text: "Мета – це світло, яке показує шлях, навіть у найтемнішій ночі.",
		author: "Лао Цзи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 8,
		text: "Мета – це мрія з планом дій.",
		author: "Наполеон Хілл",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 9,
		text: "Мета – це секретна зброя успішних людей.",
		author: "Гарі Райнер",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 10,
		text: "Мета – це фокус, який дозволяє нам побачити те, що інші не помічають.",
		author: "Джером Фішер",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 11,
		text: "Мета – це мрія, зроблена конкретною та вимірюваною.",
		author: "Пітер Друкер",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 12,
		text: "Мета – це план, що призводить до успіху.",
		author: "Конфуцій",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 13,
		text: "Без мети ми пливемо за течією, а з метою - ми керуємо напрямом.",
		author: "Стівен Кови",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 14,
		text: "Щастя – це бути в сьогоденні, і воно доступне саме зараз.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 15,
		text: "Саме зараз ви можете змінити свою долю.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 16,
		text: "Саме зараз - це Ваш час діяти, не чекайте кращого моменту.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 17,
		text: "Щастя – це внутрішній спокій та гармонія із собою.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 18,
		text: "Щастя це коли ви задоволені тим, що у вас є.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 19,
		text: "Щастя – це віддати бабусі онуків та зайнятися покупками.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 20,
		text: "Колись ти згадуватимеш цей час, як найщасливіший у своєму житті! Насолоджуйся тут і зараз!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 21,
		text: "Якщо ти вмієш читати та аналізувати – це неймовірна перевага.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 23,
		text: "не давайте нікому дані своєї картки.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 24,
		text: "користуйтесь післяплатою.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 25,
		text: "використовуйте функцію Безпечне замовлення.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 26,
		text: "ніколи не перераховуйте передоплат або авансів.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 27,
		text: "перевіряйте доступними способами репутацію продавця.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 28,
		text: "не натискайте невідомі посилання.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 29,
		text: "не натискайте посилання від невідомих контактів.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 30,
		text: "перевіряйте комплектацію та цілісність товару на пошті.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 31,
		text: "Ціна бездіяльності набагато вища за ціну кожної помилки.",
		author: "О. Хартман",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 32,
		text: "Бізнес будується на тих людях, яким не байдуже.",
		author: "Є. Черняк",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 33,
		text: "Дбаючи про щастя інших, ми знаходимо своє власне.",
		author: "Платон",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 34,
		text: "Рівність створює дружбу.",
		author: "Платон",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 35,
		text: "Ми щасливі лише тоді, коли перебуваємо у сьогоденні.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 36,
		text: "У момент проблеми ви не підніметеся до рівня своїх очікувань, а впадете до рівня своїх можливостей.",
		author: "Є. Черняк",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 37,
		text: "Якщо ти не одержимий – ти вже програв!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 38,
		text: "Одержимість – це той мотор, який призводить до перемоги, коли всі інші вже здалися.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 39,
		text: "Одержимість – це сила, яка змушує тебе долати всі перешкоди, навіть коли весь світ проти тебе.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 40,
		text: "Підприємець це той, хто бачить можливості там, де інші бачать труднощі.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 41,
		text: "Справжній підприємець не боїться провалів, він боїться згаяти шанс на успіх.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 42,
		text: "Підприємець – це той, хто бачить у кожній невдачі можливість для нового початку.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 43,
		text: "Бізнес – це мистецтво бачити не лише те, що є, а й те, що можливо.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 44,
		text: "У світі бізнесу немає сталості, лише постійні зміни та можливості.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 45,
		text: "Усі успішні маркетплейсери колись починали з простого акаунту продавця.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 46,
		text: "Щастя скорочене та непостійне. Живіть зараз та сьогодні!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 47,
		text: "Яка головна мета Вашого Життя? Що Ви зробили сьогодні, щоб наблизити її?",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 48,
		text: "Пообіцяй, що не зраджуватимеш з іншими маркетплейсами...",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 49,
		text: "Ми намагаємося зробити цей bago найкращим!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 50,
		text: "Мистецтво підприємництва полягає в тому, щоб з нічого зробити щось.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 51,
		text: "Краще, коли дружба виросла з бізнесу, ніж коли бізнес виріс із дружби.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 52,
		text: "Наш бізнес – це люди, яким ми продаємо каву, а не кава, яку ми продаємо людям.",
		author: "Г. Бехар",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 53,
		text: "Визначний бізнес повинен мати совість.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 54,
		text: "це місце, де народжуються нові можливості",
		author: "",
		advice: false,
		bagoTitle: true,
	},
	{
		id: 55,
		text: "це місце для стрибка в майбутнє.",
		author: "",
		advice: false,
		bagoTitle: true,
	},
	{
		id: 56,
		text: "Якщо ви чекаєте на ідеальні умови, ви ніколи нічого не зробите.",
		author: "Еклезіаст",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 57,
		text: "Будьте Зміною, яку ви хочете бачити у світі.",
		author: "Махатма Ганді",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 58,
		text: "Негативний результат – це також результат!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 59,
		text: "Я не зазнав поразки. Я щойно знайшов 10,000 способів, які не працюють.",
		author: "Томас Едісон",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 60,
		text: "Єдиний спосіб зробити чудову роботу – любити те, що ви робите.",
		author: "Стів Джобс",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 61,
		text: "Ваш час обмежений, не витрачайте його, живучи чужим життям.",
		author: "Стів Джобс",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 62,
		text: "Вам не потрібні 100 книг про саморозвиток, Вам потрібно тільки самодисципліна та дії.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 63,
		text: "Момент – це точка найбільшої повноти Вашого життя.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 64,
		text: "Життя – це самий дорогоцінний подарунок твоїх батьків.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 65,
		text: "У бізнесі кожна хвилина має власну ціну.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 66,
		text: "Час – найважливіший інвестиційний актив у бізнесі.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 67,
		text: "Твій вільний Час – це міра успіху у світі підприємництва.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 68,
		text: "Істина полягає в тому, що Щастя завжди швидкоплинне. Та коли приходить Щастя – радійте! Радійте, як дитина, і ніколи не відмовляйте собі в цьому!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 69,
		text: "Думка – це енергія.",
		author: "Джозеф Мерфи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 70,
		text: "Якщо чітко записати проблему, то справа наполовину вирішена.",
		author: "Закон Кідліна",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 71,
		text: "Впевнено йди за своїми мріями. Живи життям, яким тобі хочеться жити.",
		author: "Японське прислів'я",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 72,
		text: "Думка – це початок всього. Думка створює світ.",
		author: "Лао–Цзи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 73,
		text: "Природа не поспішає, але всього досягає.",
		author: "Лао–Цзи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 74,
		text: "Приймайте важке так само легко, як і просте; велике – так само легко, як і маленьке.",
		author: "Лао–Цзи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 75,
		text: "Сама глибока річка робиться з маленьких струмочків.",
		author: "Лао–Цзи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 76,
		text: "Щастя – це шлях, а не місце, куди ми приходимо.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 77,
		text: "Той, хто надто багато каже, часто нічого не робить.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 78,
		text: "Найкращий спосіб побачити світло – це бути в темряві.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 79,
		text: "Той, хто надто зайнятий, щоб дбати про своє здоров'я, рано чи пізно буде змушений дбати про свою хворобу.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 80,
		text: "Два найважливіші дні у вашому житті – це день, коли ви народилися, і день, коли ви зрозуміли, навіщо.",
		author: "Марк Твен",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 81,
		text: "Ви просто пливете за течією, а я бачу гребінь хвилі і щосили гребу до нього!",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 82,
		text: "Мрія дана з силами здійснити її.",
		author: "Е. Хемінгуей",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 83,
		text: "Все найцінніше у цьому житті ми отримуємо безкоштовно.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 84,
		text: "Бізнес – це втілення мрії. Ненавиджу тих, для кого це просто гроші.",
		author: "Рік Рубін",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 85,
		text: "95% людей мають мрію, яку можна здійснити до кінця тижня, а вони її роблять мрією всього життя.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 86,
		text: "Коли ви чогось дуже сильно бажаєте, в гру вступає Закон притягнення.",
		author: "Е. Карнегі",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 87,
		text: "Ніхто мені не казав, що це неможливо, тож я зробив роботу.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 88,
		text: "Коли мені кажуть, що це неможливо – я працюю з подвійною старанністю.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 89,
		text: "Щастя – це знайти того, хто шукав тебе.",
		author: "Омар Хаям",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 90,
		text: "Проблема цього світу в тому, що виховані люди сповнені сумнівів, а ідіоти сповнені впевненості.",
		author: "Ч. Буковський",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 91,
		text: "Бережіть в собі людину.",
		author: "А.П. Чехов",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 92,
		text: "Хто не жив посеред бурі, той ціни не знає силі.",
		author: "Леся Українка",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 93,
		text: "Хто не журиться, не плаче, то той ніколи й не радіє.",
		author: "Тарас Шевченко",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 94,
		text: "Вогонь запеклих не пече.",
		author: "Тарас Шевченко",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 95,
		text: "Поразка – це наука, ніяка перемога так не вчить.",
		author: "Ліна Костенко",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 96,
		text: "Активність – це ключ до успіху. Життя дає шанс лише тим, хто не просто чекає, а діє.",
		author: "А.Ейнштейн",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 97,
		text: "Життя подібне до велосипеда: щоб зберегти рівновагу, потрібно рухатися.",
		author: "А.Ейнштейн",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 98,
		text: "Щоразу, коли ти дієш, ти створюєш енергію, яка повертається до тебе у вигляді нових можливостей.",
		author: "Лао Цзи",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 99,
		text: "Козак є людина вільна, а свобода – над усіма законами.",
		author: "Тарас Бульба",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 100,
		text: "Підприємництво – це сміливість приймати рішення, незважаючи на невизначеність та ризики.",
		author: "Стів Джобс",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 101,
		text: "Одержимість – це іскра, яка запалює шлях до успіху, надихаючи нас долати всі труднощі на шляху.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 102,
		text: "Коли пристрасть стає вашим способом життя, ви стаєте непереможними.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 103,
		text: "Бізнесмена часто запитують: „Чому?“. Це хороше питання, але не менш важливе: „Чому б і ні?“",
		author: "Дж. Безос",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 104,
		text: "Життя – це те, що відбувається з тобою, поки ти жваво будуєш інші плани.",
		author: "Джон Леннон",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 105,
		text: "Ваше життя на 10% залежить від того, що з вами відбувається, і на 90% від того, як ви реагуєте на ці події.",
		author: "Джон Максвелл",
		advice: false,
		bagoTitle: false,
	},
	/*{
        id: 106,
        text: "Наша свідомість – це все. Ви стаєте тим, що думаєте.",
        author: "Будда",
        advice: false,
        bagoTitle: false,
    },*/
	{
		id: 107,
		text: "Найкращий час, щоб посадити дерево, було 20 років тому. Наступний найкращий час – сьогодні.",
		author: "Китайське прислів'я",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 108,
		text: "Ти ніколи не перетнеш океан, якщо не наберешся сміливості втратити з уваги берег.",
		author: "Христофор Колумб",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 109,
		text: "Найкращою помстою є величезний успіх.",
		author: "Френк Сінатра",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 110,
		text: "Перешкоди – це ті страшні речі, які ви бачите, коли відводите очі від мети.",
		author: "Генрі Форд",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 111,
		text: "Впевнено йди за своїми мріями. Живи життям, яким тобі хочеться жити.",
		author: "Генрі Девід Торо",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 112,
		text: "Сім разів упади – вісім разів устань.",
		author: "Японське прислів'я",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 113,
		text: "Коли зачиняються одні двері до щастя – відчиняються інші, але ми так довго дивимося на зачинені двері, що не помічаємо ті, які вже відчинилися для нас.",
		author: "Хелен Келлер",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 114,
		text: "Щастя не буває у готовому вигляді. Воно походить від ваших власних дій.",
		author: "Далай Лама",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 115,
		text: "Якщо вітер стих, берись за весла.",
		author: "Латинське прислів'я",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 116,
		text: "Людина, яка ніколи не помилялася, ніколи не намагалася зробити щось нове.",
		author: "Альберт Ейнштейн",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 117,
		text: "Труднощі роблять наше життя цікавішим, а їхнє подолання наповнює наше життя змістом.",
		author: "Джошуа Марін",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 118,
		text: "Без відхилення від норми прогрес неможливий.",
		author: "Френк Заппа",
		advice: false,
		bagoTitle: false,
	},
	/*{
        id: 119,
        text: "Той, хто не хоче працювати на себе – працюватиме на інших.",
        author: "",
        advice: false,
        bagoTitle: false,
    },*/
	/* {
        id: 120,
        text: "Той, хто не хоче платити за освіту – платитиме за невігластво.",
        author: "",
        advice: false,
        bagoTitle: false,
    },*/
	{
		id: 121,
		text: "Пам'ятай, що неотримання того, що ти хочеш, часом є справжньою усмішкою долі.",
		author: "Далай Лама",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 122,
		text: "Коли здається, що весь світ налаштований проти тебе, пам'ятай, що літак злітає проти вітру.",
		author: "Генрі Форд",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 123,
		text: "Ви повинні горіти ідеєю, або проблемою, або помилкою, яку ви хочете виправити. Якщо у вас недостатньо пристрасті з самого початку, ви ніколи не досягнете успіху.",
		author: "Стів Джобс",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 124,
		text: "Якість означає виконувати роботу правильно, коли ніхто не дивиться.",
		author: "Генрі Форд",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 125,
		text: "Ваші найбільш незадоволені клієнти – ваше найбільше джерело навчання.",
		author: "Білл Гейтс",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 126,
		text: "Коли ви недооцінюєте те, що робите, світ недооцінюватиме те, ким ви є.",
		author: "Опра Вінфрі",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 103,
		text: "Якщо ти не керуєш своїми цілями, то твоїми цілями керує хтось інший.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 127,
		text: "Проактивність – це коли ти сам вигадуєш, що робити.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 128,
		text: "Тільки встановленна ціль допомагає правильно розставити пріоритети.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 129,
		text: "Гроші найчастіше платять за відмову від власних цілей.",
		author: "Karmalogic",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 130,
		text: "Перед злетом тебе доля обов'язково проведе через дно.",
		author: "Karmalogic",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 131,
		text: "Несвідоме завжди вибирає найкращий варіант із наявних.",
		author: "Олексій Сітніков",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 132,
		text: "Не можна від цього світу взяти більше, ніж ти віддав.",
		author: "Закони рівноваги",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 133,
		text: "Енергія йде туди, куди ми спрямовуємо своє мислення. Особливо не щастить людям, які бажають іншим зла. Думайте позитивно, випромінюйте добро.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 134,
		text: "Всі геніальні люди дуже незручні, тому що вони нарциси, вони ніколи не йдуть у ногу та не підлаштовуються ні під кого.",
		author: "Олексій Сітніков",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 135,
		text: "Дорогою до мети – аналізуйте шлях, а досягнувши вершини, ставте нову.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 136,
		text: "Завдання, які Ви в минулому не вирішили, Ви не збираєтеся їх вирішувати. Навчіться відпускати минуле і йдіть далі.",
		author: "",
		advice: false,
		bagoTitle: false,
	},
	{
		id: 137,
		text: "Не шукайте одну продаж, намагайтеся знайти потік.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
	{
		id: 138,
		text: "bago – це місце, де народжуються нові можливості.",
		author: "",
		advice: true,
		bagoTitle: false,
	},
];

export const noRegisterPhrasesArray = [
	{
		id: 1,
		text: "Будь-який шлях починається з першого кроку.",
		author: "",
	},
	{
		id: 2,
		text: "За будь-якими дверима може бути початок нового шляху.",
		author: "",
	},
	{
		id: 3,
		text: "Вихід із ситуації, що склалася, найчастіше, знаходиться там же, де і ВХІД.",
		author: "",
	},
	{
		id: 4,
		text: "Початок – це половина всього.",
		author: "Піфагор",
	},
	{
		id: 5,
		text: "Початок – це момент, коли мрії перетворюються на реальність.",
		author: "",
	},
	{
		id: 6,
		text: "Почни, зробивши те, що необхідно, потім зроби те, що можливо, і раптом ти побачиш, що зробив неможливе.",
		author: "",
	},
	{
		id: 7,
		text: "Важливо не лише розпочати, а розпочати правильно.",
		author: "Арістотель",
	},
	{
		id: 8,
		text: "Усі великі справи починаються з того моменту, коли ви вирішуєте щось зробити.",
		author: "Жорж Бізе",
	},
	{
		id: 9,
		text: "Початок – це момент у житті, коли ви вирішуєте, що чогось хочете найбільше.",
		author: "",
	},
	{
		id: 10,
		text: "Кожен початок – це можливість.",
		author: "Цицерон",
	},
	{
		id: 11,
		text: "Кожен Вхід – це можливість.",
		author: "Цицерон",
	},
	{
		id: 12,
		text: "Початок – це найважливіша частина твоєї роботи.",
		author: "Платон",
	},
	{
		id: 13,
		text: "Подивися на ці дві кнопки! Ти думав Невідомість виглядає якось інакше?",
		author: "",
	},
	{
		id: 14,
		text: "bago – це місце, де народжуються нові можливості.",
		author: "",
	},
	{
		id: 15,
		text: "А ти проти маркетплейсів, що зажерлися? У тебе є Вибір!",
		author: "",
	},
	{
		id: 16,
		text: "Ми намагаємося зробити цей bago найкращим!",
		author: "",
	},
	{
		id: 17,
		text: "Bago – це наш дім. Щоб увійти – треба зареєструватися.",
		author: "",
	},
	{
		id: 18,
		text: "bago – це місце для стрибка в майбутнє.",
		author: "",
	},
	{
		id: 19,
		text: "Хочеш стосунків? Лише після реєстрації!",
		author: "",
	},
	{
		id: 20,
		text: "Б'ємося об заклад, ти не зможеш подати оголошення без реєстрації?",
		author: "",
	},
	{
		id: 21,
		text: "Будь сміливим, зроби вибір)",
		author: "",
	},
	{
		id: 22,
		text: "Пообіцяй, що не зраджуватимеш з іншими маркетплейсами...",
		author: "",
	},
	{
		id: 23,
		text: "Саме зараз – це момент у житті, який є ключем до нових можливостей та перспектив.",
		author: "",
	},
	{
		id: 24,
		text: "Саме зараз - це момент, коли ви залишаєте минуле і вступаєте в майбутнє.",
		author: "",
	},
	{
		id: 25,
		text: "Саме зараз – той момент, коли треба зробити вибір, щоби почати щось нове.",
		author: "",
	},
	{
		id: 26,
		text: "Зараз настав саме той момент, коли треба ухвалити непросте рішення)) >>>",
		author: "",
	},
	{
		id: 27,
		text: "Вхід – це той момент, коли світ стає доступним.",
		author: "",
	},
	{
		id: 28,
		text: "Щоб щось змінити, іноді потрібно спочатку Увійти.",
		author: "",
	},
	{
		id: 29,
		text: "Вхід у незвідане – це перший крок до пригод.",
		author: "",
	},
	{
		id: 30,
		text: "Вхід у новий світ вимагає сміливості та рішучості.",
		author: "",
	},
	{
		id: 31,
		text: "Вхід – це той момент, коли ми перетинаємо поріг можливостей.",
		author: "",
	},
	{
		id: 32,
		text: "Будь-який Вхід – це новий досвід, а будь-який досвід – це майбутня можливість.",
		author: "",
	},
	{
		id: 33,
		text: "У житті бувають різні Входи, і кожен із них призводить до нового шляху.",
		author: "",
	},
	{
		id: 34,
		text: "Саме зараз – це можливість зробити перший крок до своєї мети.",
		author: "",
	},
	{
		id: 35,
		text: "Важливо пам'ятати, що кожен Вхід може призвести до нових виходів.",
		author: "",
	},
	{
		id: 36,
		text: "Щоб використовувати весь функціонал сайту, вам необхідно увійти або зареєструватися.",
		author: "",
	},
	{
		id: 37,
		text: "У житті бувають різні Реєстрації, і кожна з них призводить до нового шляху)",
		author: "",
	},
	{
		id: 38,
		text: "Реєстрація – це той момент, коли ми перетинаємо поріг можливостей.",
		author: "",
	},
	{
		id: 39,
		text: "Bago – постійне місце Реєстрації вашого бізнесу.",
		author: "",
	},
	{
		id: 40,
		text: "Справжнє кохання не боїться нічого, навіть Реєстрації.",
		author: "",
	},
	{
		id: 41,
		text: "Зареєструємо наші відносини?",
		author: "",
	},
	{
		id: 42,
		text: "У Реєстрації наших відносин не буде розлучення)",
		author: "",
	},
	{
		id: 43,
		text: "Зараз – ти працюєш на час. Завтра – час працює на тебе. Дій!",
		author: "",
	},
	{
		id: 44,
		text: "Бажаєш бачити тут свої Оголошення? Реєструйся та публікуй їх зовсім безкоштовно!",
		author: "",
	},
	{
		id: 45,
		text: "Нам по дорозі, якщо ти готовий до постійного розвитку та навчання.",
		author: "",
	},
	{
		id: 46,
		text: "Нам по дорозі, якщо ти готовий будувати надійні стосунки з клієнтами та партнерами.",
		author: "",
	},
	{
		id: 47,
		text: "Ціна бездіяльності, набагато вища за ціну кожної помилки.",
		author: "О. Хартман",
	},
	{
		id: 48,
		text: "Якщо ти не одержимий – ти вже програв!",
		author: "",
	},
	{
		id: 49,
		text: "Зробіть перший крок у вірі. Вам не потрібно бачити всі сходи, просто зробіть перший крок.",
		author: "Мартін Лютер Кінг мол.",
	},
	{
		id: 50,
		text: "Подорож у тисячу миль починається з одного кроку.",
		author: "Лао-Цзи",
	},
	{
		id: 51,
		text: "Складні справи починаються з найпростіших. Великі справи починаються з малих.",
		author: "Лао-Цзи",
	},
	{
		id: 52,
		text: "Не бійтеся бути повільними, бійтеся залишатись на місці.",
		author: "",
	},
	{
		id: 53,
		text: "Молодість завжди шукає щось нове. Якщо Ви боїтеся змін – Ви на порозі старості.",
		author: "",
	},
	{
		id: 54,
		text: "Щастя – це шлях, а не місце, куди ми приходимо.",
		author: "",
	},
	{
		id: 55,
		text: "Bago – це наш дім. Заходьте у гості!",
		author: "",
	},
	{
		id: 56,
		text: "Мрія дана з силами здійснити її.",
		author: "Е. Хемінгуей",
	},
	{
		id: 57,
		text: "Все найцінніше у цьому житті ми отримуємо безкоштовно.",
		author: "",
	},
	{
		id: 58,
		text: "Найкращий спосіб пізнати людину – це побувати у нього в гостях. О-ось там - кнопочка ВХІД;)",
		author: "",
	},
	{
		id: 59,
		text: "Гостей у будинку багато не буває. О-ось там – кнопочка ВХІД;)",
		author: "",
	},
	{
		id: 60,
		text: "Вітаємо тебе! О, Таємничий Незнайомцю! Бо інші вже зареєструвалися)",
		author: "",
	},
	{
		id: 61,
		text: "Не чекайте. Час ніколи не буде доречним.",
		author: "Наполеон Хілл",
	},
	{
		id: 62,
		text: "Впевнено йди за своїми мріями. Живи життям, яким тобі хочеться жити.",
		author: "Генрі Девід Торо",
	},
	{
		id: 63,
		text: "Саме зараз – це момент у житті, який є ключем до нових можливостей та перспектив.",
		author: "",
	},
	{
		id: 64,
		text: "Пообіцяй, що не змінюватимеш з іншими маркетплейсами...",
		author: "",
	},
];
